.Carousel{
    position: relative;
    display: flex;
    padding: 3rem;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    align-items: center;
    overflow: hidden;
    width: 500px;
}

.Carousel_button{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: #045E7B;
    border: none;
    border-radius: 100%;
    padding: 1.5rem;
    z-index: 2;
    cursor: pointer;
}

#button_left{
    rotate: 90deg;
    left: 20px;
}

#button_right{
    rotate: -90deg;
    right: 20px;
}

.Carousel_slider{
    display: flex;
    gap: 80px;
    transition: all 0.5s;
}

@media screen and (max-width: 650px) {
    .Carousel{
        position: relative;
        display: flex;
        padding: 3rem;
        background-color: white;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        border-radius: 12px;
        align-items: center;
        overflow-x: scroll;
        width: 400px;
    }

    #button_left{
        display: none;
    }
    
    #button_right{
        display: none;
    }
    
    .Carousel_slider{
        display: flex;
        gap: 80px;
        transition: all 0.5s;
    }
}

@media screen and (max-width: 550px) {
    .Carousel{
        width: 300px;
    }
}

@media screen and (max-width: 430px) {
    .Carousel{
        width: 200px;
    }
}