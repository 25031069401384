.Icone{
    display: flex;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    transition: all .5s;
    border-radius: 10px;
    padding: .25rem 1rem;
}

.Icone:hover{
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.40);
}

@media screen and (max-width: 760px) {
    .Icone{
        padding: 1rem;
    }
}