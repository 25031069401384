*{
    text-decoration: none;
    color: #5A5451;
    list-style: none;
    margin: 0;
    padding: 0;
    font-family: 'Rubik', sans-serif;
}

a{
    font-size: 1rem;
    color: #5A5451;
}

p{
    font-size: 1rem;
    color: #5A5451; 
}

h1{
    font-size: 3.5rem;
    color: #5A5451; 
    font-weight: 500;
}

h2{
    font-size: 2.5rem;
    color: #5A5451;
    font-weight: 500; 
}

h3{
    font-size: 2rem;
    color: #5A5451; 
}

h4{
    font-size: 1.5rem;
    color: #5A5451; 
}

img{
    object-fit: cover;
}

.animationUp{
    animation: animUp 0.5s ease-in-out;
}

.animationDown{
    animation: animDown 0.5s ease-in-out;
}

.animationLeft{
    animation: animLeft 0.5s ease-in-out;
}

.animationRight{
    animation: animRight 0.5s ease-in-out;
}

.delay_1{
    animation-duration: .7s;
}

.delay_2{
    animation-duration: .8s;
}

.delay_3{
    animation-duration: .9s;
}

.delay_4{
    animation-duration: 1s;
}

.delay_5{
    animation-duration: 1.1s;
}

.delay_6{
    animation-duration: 1.2s;
}

.delay_7{
    animation-duration: 1.3s;
}

.delay_8{
    animation-duration: 1.4s;
}

.delay_9{
    animation-duration: 1.5s;
}

.delay_10{
    animation-duration: 1.6s;
}


@keyframes animUp{
    0%{
        opacity: 0;
        transform: translate(0px, 10px);

    }
    100%{
        opacity: 1;
        transform: translate(0px, 0%);

    }
}

@keyframes animDown{
    0%{
        opacity: 0;
        transform: translate(0px, -10px);

    }
    100%{
        opacity: 1;
        transform: translate(0px, 0%);

    }
}

@keyframes animLeft{
    0%{
        opacity: 0;
        transform: translate(-50px, 0px);
    }
    100%{
        opacity: 1;
        transform: translate(0px, 0%);

    }
}

@keyframes animRight{
    0%{
        opacity: 0;
        transform: translate(50px, 0px);

    }
    100%{
        opacity: 1;
        transform: translate(0px, 0%);

    }
}