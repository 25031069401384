.About_Us{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5rem;
}

.AboutUs_header{
    background-image: url("../assets/bg_home.png");
    background-size: cover;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    padding: 14rem 0rem 10rem 0rem;
}

.AboutUs_header h1{
    color: white;
}

.AboutUs_header p{
    color: white;
}

.AboutUs_cards{
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding: 0rem 2rem;
    width: calc(100% - 4rem);
    max-width: 1440px;
}


.AboutUs_product{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0rem 2rem;
    width: calc(100% - 4rem);
    max-width: 1440px;
}

.AboutUs_product ul{
    padding-left: 2rem;

}

.AboutUs_product li{
    list-style: url(../assets/icones/check-line.png);
}

.Button_catalog{
    background-color: #045E7B;
    border-radius: 100px;
    border: none;
    font-size: 1rem;
    color: white;
    padding: 1rem 2rem;
    width: fit-content;
    transition: all .5s;
    cursor: pointer;
}

.Button_catalog:hover{
    background-color: #005A79;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 760px) {
    .AboutUs_cards{
        flex-direction: column;
        padding: 0rem;
        width: calc(100% - 0rem);
    }


    
}