.Main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10rem;
}

.Main_header{
    background-image: url("../assets/bg_home.png");
    background-size: cover;
    object-fit: cover;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    padding: 28rem 0rem 20rem 0rem;
}

.Main_header h1{
    color: white;
}

.Main_header p{
    color: white;
}

.Main_information{
    display: flex;
    width: calc(100% - 10rem);
    max-width: calc(1440px - 10rem);
    padding: 0rem 5rem;
    gap: 5rem;
}

.Main_information_left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.Main_information_right{
    display: flex;
    gap: 1.5rem;
    flex-direction: column;
    width: 50%;
    height: 100%;
}

.Main_OurProducts{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 0rem 5rem;
}

.Main_OurProducts_image{
    max-width: 1440px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    transition: all .5s;
    border-radius: 10px;
}


.Main_OurProducts_Buttons{
    display: flex;
    align-items: center;
    justify-content: center;
}

.Main_Link{
    background-color: #045E7B;
    color: white;
    font-weight: 600;
    padding: 1.5rem;
    border-radius: 100px;
    width: fit-content;
    transition: all .5s;
}

.Main_Link:hover{
    background-color: #005A79;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.Main_DfactoGreen{
    display: flex;
    max-width: 1440px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    margin: 2rem;
}

.Main_DfactoGreen_img{
    width: 50%;
    border-radius: 10px 0px 0px 10px;
}

.Main_DfactoGreen_text{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    gap: 2rem;
    padding: 0rem 2rem;
}

@media screen and (max-width: 1100px) {
    .Main_header{
        padding: 28rem 4rem 20rem 4rem;
        width: calc(100% - 8rem);
    }

    .Main_header h1{
        color: white;
        text-align: center;
    }
    
    .Main_header p{
        color: white;
        text-align: center;
    }

    .Main_DfactoGreen h1{
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 760px) {
    .Main{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5rem;
    }

    .Main_information{
        display: flex;
        flex-direction: column;
        width: calc(100% - 4rem);
        max-width: calc(1440px - 10rem);
        padding: 0rem 2rem;
        gap: 2.5rem;
    }
    
    .Main_information_left{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }
    
    .Main_information_right{
        display: flex;
        gap: 1.5rem;
        flex-direction: column;
        height: 100%;
        width: 100%;
    }

    .Main_DfactoGreen{
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 0rem 0rem 2rem 0rem;
    }
    
    .Main_DfactoGreen_img{
        height: 20rem;
        width: 100%;
        border-radius: 10px 10px 0px 0px;
    }
    
    .Main_DfactoGreen_text{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        gap: 2rem;
        padding: 0rem 2rem;
    }

    .Main_header {
        padding: 11rem 1rem 8rem 1rem;
        width: calc(100% - 2rem);
    }

    .Main_OurProducts{
        padding: 0rem 1rem;
        width: calc(100% - 2rem);
        align-items: center;
    }
}

