.Slide_img{
    width: 500px;
    height: 500px;
    border-radius: 10px;
    
}

@media screen and (max-width: 650px) {
    .Slide_img{
        width: 400px;
        height: 400px;
    }
}

@media screen and (max-width: 550px) {
    .Slide_img{
        width: 300px;
        height: 300px; 
    }
}

@media screen and (max-width: 550px) {
    .Slide_img{
        width: 200px;
        height: 200px; 
    }
}