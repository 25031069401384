.Card{
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 2rem;
    gap: 1.5rem;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    transition: all .5s;
}

.Card_header{
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.Card img{
    width: 4.5rem;
    height: 4.5rem;
    object-fit: cover;
}

.Card_header_titre{
    font-size: 1rem;
    font-weight: 600;
}

@media screen and (max-width: 760px) {
    .Card{
        width: calc(100% - 4rem);
    }
}