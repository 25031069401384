.SelectLangue {
    
}

.SelectLangue_open {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    display: flex;
    background: rgba(30, 33, 36, 0.30);
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.SelectLangue_buttons {
    display: none;
}

.SelectLangue_open .SelectLangue_buttons {
    display: flex;
    background-color: white;
    flex-direction: column;
    padding: 3rem 3rem;
    border-radius: 10px;
}



.SelectLangue_button {
    display: flex;
    background-color: white;
    align-items: center;
    cursor: pointer;
    gap: 0.5rem;
    padding: 1rem .5rem;
    border-radius: 6px;
    transition: all .25s ease-in-out;
}

.SelectLangue_button:hover {
    background: rgba(30, 33, 36, 0.30);
}

.SelectLangue_open  .SelectLangue_button_menu{
    display: none;
}

.SelectLangue_buttons_titre{
    padding-bottom: 1rem;
}

.SelectLangue_button p {
    font-size: 1.25rem;
    font-weight: 700;
}

.arrowLangue {
    transition: all 0.25s ease-in-out;
}

.SelectLangue_open .arrowLangue {
    transform: rotate(-180deg);
}


.SelectLangue_button img {
    width: auto;
}