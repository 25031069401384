.Rgpd{
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: (100% - 2rem);
    padding: 1rem 1rem;
    max-width: 1440px;
}

.Rgpd_texte{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}