.Catalog{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5rem;
}

.Catalog_cards{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0rem 2rem;
    width: calc(100% - 4rem);
    max-width: 1440px;
}

.Catalog_cards .Card_header_titre {
    font-size: 1.5rem;
    font-weight: 600;
}

.Catalog_cards_liste{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    max-width: 1440px;
}

.Catalog_cards .Card:hover{
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.40);
}


.Catalog_cards .Card{
    width: calc(100% - 4rem);
    gap: 0;
}

.Catalog_layout{
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 3rem;
}

.Catalog_briefing{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    padding: 2rem;
    gap: 1.5rem;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    transition: all .5s;
}

@media screen and (max-width: 825px) {
    .Catalog_layout{
        flex-direction: column;
    }
}