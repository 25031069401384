.Menu{
    display: flex;
    align-items: center;
}

.Menu_liste{
    display: flex;
    align-items: center;
    gap: 16px;
}

.Menu_liste_item:hover{
    color: #005A79;
    transition: .5s
}

.Menu_liste li a{   
    font-weight: 700;
}

@media screen and (min-width: 1100px) {

}

@media screen and (max-width: 1100px) {
    .Menu{
        display: none;
    }
}