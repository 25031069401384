.OurServices{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5rem;
}

.OurServices_intro{
    display: flex;
    width: calc(100%-4rem);
    max-width: 1440px;
    padding: 5rem 2rem;
}

.OurServices_intro_text{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.OurServices_intro_text li{
    list-style: url(../assets/icones/check-line.png);
}

.OurServices_intro_text_liste{
    padding-left: 1.5rem;
}

.OurServices_cards{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0rem 2rem;
    width: calc(100% - 4rem);
    max-width: 1440px;
}

.OurServices_cards_liste{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    width: calc(100%);
}

@media screen and (max-width: 1100px) {
    .OurServices_intro{
        display: flex;
        flex-direction: column;
        width: calc(100% - 4rem);
        max-width: 1440px;
        padding: 5rem 2rem;
    }
}
    
@media screen and (max-width: 760px) {
    .OurServices_cards{
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 0rem 2rem;
        width: calc(100% - 4rem);
        max-width: 1440px;
    }

    .OurServices_cards_liste{
        align-items: flex-start;
        justify-content: flex-start;
    }    
}