.Contact{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5rem;
}


.Contact_content{
    width: calc(100% - 4rem);
    max-width: 1440px;
    display: flex;
    justify-content: space-between;
    padding: 0rem 2rem;
}

.Contact_content_left{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 40%;
}

.Contact_content_form{
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.Contact_content_form h2{
    font-size: 1.5rem;
}

@media screen and (max-width: 950px) {
    .Contact_content{
        flex-direction: column;
    }

    .Contact_content_left{
        flex-direction: row;
        width: 100%;
    }
    
    .Contact_content_form{
        width: 100%;
        padding-top: 3rem;
    }
    
}

@media screen and (max-width: 800px) {
    .Contact_content{
        flex-direction: column;
    }

    .Contact_content_left{
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        width: 100%;
    }
}