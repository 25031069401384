.Footer{
    background-color: #005A79;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 5rem;
}

.Footer_container{
    width: 1440px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3.5rem 2rem;
}

.Footer_container_center{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.Footer_container_center_link{
    color: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: fit-content;
}

.Footer_container_center_link a{
    color: white;
    
}

.Footer_container_center_link span{
    background-color: white;
    transition: all .2s;
    gap: 2px;
    height: 2px;
    width: 0px;
}

.Footer_container_center_link:hover span{
    height: 2px;
    width: 100%;
}

.Footer_container_right{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.Link_mail{
    color: white;
}

.Footer_container_reseau{
    display: flex;
    gap: 2rem;
}

.Footer_container_center_right{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 20%;
    gap: 2rem;
}


@media screen and (max-width: 1200px) {
.Footer_container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 3rem;
    padding: 0;
}
.Footer_container_center{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0rem 2.5rem;
    margin-top: -3rem;
}

.Footer_container_center_right{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: auto;
    gap: 1rem;
    padding: 0rem 2.5rem;

}

.Footer_container_right{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0rem 2.5rem;
}
}

@media screen and (max-width: 760px) {
}