.Header{
    position: fixed;
    left: 0;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    padding: 0rem 2rem 0rem 2rem;
    width: calc(100% - 4rem);
    background-color: white;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.04)) drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.06));
    animation: navBarFadeIn 0.5s ease-in-out;
    animation-delay: 0.9;
}

@keyframes navBarFadeIn{
    0%{
        opacity: 0;
        transform: translate(0px, -10%);

    }

    100%{
        opacity: 1;
        transform: translate(0px, 0%);

    }
}

@media screen and (max-width: 1100px) {
    .Header_image{
        width: 70%;
    }
}

@media screen and (max-width: 380px) {
    .Header{
        padding: 0rem;
        width: 100%;
    }

    .Header_image{
        width: 80%;
    }
}