.OurProducts{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5rem;
}

.Main_OurProducts_Buttons{
    width: 100%;
    display: flex;
    justify-content: space-around;
}

@media screen and (max-width: 760px) {
    .Main_OurProducts_Buttons{
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap:1.5rem;
    }  
}