.menu-icon {
    margin: 20px auto;
    width: 20px;
    height: 14px;
    cursor: pointer;
    position: relative;
}

.menu-icon span {
    background-color: white;
    height: 2px;
    position: absolute;
    width: 100%;
    left: 0;
    transition: all 0.3s ease;
    border-radius: 10px;
}

.menu-icon span:first-child {
    top: 0;
}

.menu-icon span:nth-child(2) {
    top: 6px;
}

.menu-icon span:last-child {
    top: 12px;
}

.menu-icon_active span:nth-child(2) {
    opacity: 0;
}

.menu-icon_active span:first-child,
.menu-icon_active span:last-child {
    top: 6px;
    background-color: white;
    border-radius: 5px;
}

.menu-icon_active span:first-child {
    transform: rotate(45deg);
}

.menu-icon_active span:last-child {
    transform: rotate(-45deg);
}

.button_menu{
    background-color: #045E7B;
    padding: 0px 1rem 0px 1rem;
    border-radius: 100%;
    border: none;
    position: fixed;
    right: 5vw;
    top: 85vh;
    z-index: 10;
}

.mobile_menu{
    display: flex;
    opacity: 0;
    position: absolute;
    padding-top: 1vh;
    padding-left: 1rem;
    height: 0;
    width: calc(100% - 1rem);
    right: 0vh;
    top: 100vh;
    background-color: #045E7B;
    transition: all 0.6s ;
}

.mobile_menu_active{
    height: 100vh;
    opacity: 1;
    top: 0vh;
}

.mobile_menu_nav_liste a{
    color: white;
    font-size: 2.5rem;
}


@media screen and (min-width: 1100px) {
    .button_menu{
        display: none;
    }
}

@media screen and (max-width: 1100px) {
    .mobile_menu_nav{
        height: 95vh;
        width: 100%;
        display: flex;
        align-items: center;
    }

    .mobile_menu_nav_liste{
        height: calc(100%  - 150px);
        width: calc(100%  - 24px);
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;
    }

    .mobile_menu_nav li{
        font-size: 42px;
    }
}