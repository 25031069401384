.DfactoGreen{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5rem;
}

.DfactoGreen_product_titre{
    font-size: 2rem;
}

#DfactoGreen_header{
    background-image: url(../assets/bgDfactoGreen.png);
    background-size: cover;
}