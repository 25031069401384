.References{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5rem;
}

.Main_OurProducts_image_img{
    opacity: 0.7;
    transition: all .5s;
    animation: imageApparition 0.5s ease-in-out;
}

.Main_OurProducts_image_img:hover{
    opacity: 1;
}

@media screen and (max-width: 760px) {    
    .References{
       
    }
}


@keyframes imageApparition{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 0.7;
    }
}

#referenceDelay1{
    animation-duration: .55s;
}
#referenceDelay2{
    animation-duration: .6s;
}
#referenceDelay3{
    animation-duration: .65s;
}
#referenceDelay4{
    animation-duration: .70s;
}
#referenceDelay5{
    animation-duration: .75s;
}
#referenceDelay6{
    animation-duration: .8s;
}
#referenceDelay7{
    animation-duration: .85s;
}
#referenceDelay8{
    animation-duration: .9s;
}
#referenceDelay9{
    animation-duration: .95s;
}
#referenceDelay10{
    animation-duration: 1s;
}
#referenceDelay11{
    animation-duration: 1.05s;
}
#referenceDelay12{
    animation-duration: 1.1s;
}
#referenceDelay13{
    animation-duration: 1.15s;
}
#referenceDelay14{
    animation-duration: 1.20s;
}
#referenceDelay15{
    animation-duration: 1.25s;
}
#referenceDelay16{
    animation-duration: 1.30s;
}
#referenceDelay17{
    animation-duration: 1.35s;
}
#referenceDelay18{
    animation-duration: 1.40s;
}
#referenceDelay19{
    animation-duration: 1.45s;
}
#referenceDelay20{
    animation-duration: 1.5s;
}

