.CardCarre{
    display: flex;
    flex-direction: column;
    width: 10rem;
    height: 10rem;
    padding: 2rem;
    gap: 1.5rem;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    transition: all .5s;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.CardCarre_header{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}

.CardCarre img{
    width: 4.5rem;
    height: 4.5rem;
    object-fit: cover;
}

.CardCarre_header_titre{
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
}

.CardCarre:hover{
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.40);
}

@media screen and (max-width: 800px) {
    .CardCarre{
        width: calc(100% - 4rem);
        height: fit-content;
    }

    .CardCarre_header{
        flex-direction: row;
        width: 100%;
    }

    .CardCarre img{
        width: 3rem;
        height: 3rem;
    }
}